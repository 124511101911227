<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="申请单号" :initialContent="detail.code" :disabled="true"></inputItem>
        <inputItem title="申请人员" :initialContent="detail.add_name" :disabled="true"></inputItem>
        <inputItem title="申请时间" :initialContent="formatDate(detail.add_time,'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
        <inputItem title="项目名称" type="others" ref="pname" :initialContent="detail.pname" :otherClicked="clickProject" :disabled="disabled"></inputItem>
        <inputItem title="试点类型" v-model="detail.category" :disabled="disabled" type="select" :options="systemOption"></inputItem>
        <inputItem title="试点数量" v-model="detail.num" :disabled="disabled"></inputItem>
        <inputItem title="开始日期" type="date" name="s_time" :initialContent="formatDate(detail.s_time)" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="到期日期" type="date" name="e_time" :initialContent="formatDate(detail.e_time)" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="申请事由" v-model="detail.remark" :disabled="disabled" type="textarea"></inputItem>
        <inputItem title="开通时间" :initialContent="formatDate(detail.open_time,'yyyy-MM-dd hh:mm')" :disabled="true" v-if="detail.state>3"></inputItem>
        <inputItem title="开通人员" :initialContent="detail.open_name" :disabled="true" v-if="detail.state>3"></inputItem>
        <inputItem title="开通内容" v-model="detail.open_memo" :disabled="detail.state<4" v-if="detail.state>3" type="textarea"></inputItem>
        <div class="mobile-detail-step" v-if="detail && detail.state > 1 && flows.flowLogs && flows.flowLogs.length>0">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawer" v-if="showOptions" :onBgClicked="onBgClicked" :onSearch="onSearch" :items="projects" @optionClicked="optionClicked"> </optionDrawer>
  </div>
</template>

<script>
import { GetProjectTrial, AuditProjectTrial, SaveProjectTrial, CancelProjectTrial, OpenProjectTrial } from "@/api";
import { loadDetailAction, saveAction, auditAction, cancelAction, submitAction, loadDataOptionAction, loadProjectOptionAction } from "@/utils/action.js";
import { formatDate } from "@/utils/format.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      projects: [],
      showOptions: false,
      disabled: false,
      systemOption: [],
    };
  },
  methods: {
    formatDate: formatDate,
    onSearch: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClicked: function () {
      this.showOptions = false;
    },
    optionClicked: function (item) {
      this.detail.pid = item.id;
      this.detail.pname = item.name;
      this.$refs.pname.setContent(item.name);
      this.showOptions = false;
    },
    contentChanged: function (index, value) {
      switch (index) {
        case "s_time":
          this.detail.s_time = value;
          break;
        case "e_time":
          this.detail.e_time = value;
          break;
      }
    },
    clickProject: function () {
      this.showOptions = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.optionDrawer.show();
        }, 100);
      });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveProjectTrial(1);
          break;
        case "提交审核":
          this.saveProjectTrial(2);
          break;
        case "审核通过":
          this.auditProjectTrial(true, "同意");
          break;
        case "审核拒绝":
          this.auditProjectTrial(false, "");
          break;
        case "撤回":
          this.cancelProjectTrial();
          break;
        case "确认开通":
          this.openProjectTrial();
          break;
      }
    },
    saveProjectTrial: function (state) {
      this.detail.state = state;
      saveAction(this, SaveProjectTrial, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; });
    },
    auditProjectTrial: function (pass, memo) {
      auditAction(this, AuditProjectTrial, this.id, pass, memo);
    },
    cancelProjectTrial: function () {
      cancelAction(this, CancelProjectTrial, this.id);
    },
    openProjectTrial: function () {
      submitAction(this, OpenProjectTrial, { id: this.id, memo: this.detail.open_memo }, () => { this.$router.go(-1); }, null, true, "是否确定开通？");
    },
    dataRequest: function () {
      loadDetailAction(this, GetProjectTrial, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "试点类型", (data) => { this.systemOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>